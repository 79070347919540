/*import google fonts here*/
@import url('https://fonts.googleapis.com/css?family=Fredoka+One|Roboto');
/** how to write fonts
font-family: 'Roboto', sans-serif;
font-family: 'Fredoka One', cursive;
**/

/** these are GLOBAL unchanging styles and imports ONLY **/

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
button {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
h1, h2, h3 {
  font-family: 'Fredoka One', cursive;
  color: #67686b;
  font-weight: normal;
}
ul {
  list-style-type: none;
}
