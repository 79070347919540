/*app global*/
.App {
  text-align: center;
}
 /*app header*/
.App-header {
 height: 60px;
 padding: 10px;
}
/*navbar*/
#navLogo {
  float: left;
}
#navBurger {
  float: right;
  padding: 10px;
  padding-top: 12px;
  background-color: white;
  border: solid 3px #4294d1;
  border-radius: 100px;
}
#navMenu {
  float: right;
  margin: 5px 30px 0 0;
  padding: 8px;
  width: 70px;
  background-color: white;
  border: solid 3px #41bb93;
  border-radius: 3px;
  font-size: 14pt;
  font-weight: bold;
  color: #41bb93;
}
#navLogo:hover, #navBurger:hover, #navMenu:hover, #closeMenu:hover,
#navList li:hover, #menuSocialLinks div:hover, #footerSocialLinks div:hover,
#centerHomeMapDiv button,#aboutPromo button, #inputForm input[type=button]:hover,
#emailContact:hover, #phoneContact:hover, .itemTab:hover, .itemTab button:hover,
.foodViewHead button:hover {
  cursor: pointer;
}
/*nav menu*/
#openMenu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  max-width: 500px;
  width: 80%;
  z-index: 999999;
  background-color: #4294d1;
  color: white;
}
#closeMenu {
  float: right;
  margin: 5px 5px 0 0;
  padding: 10px;
  padding-top: 12px;
  background-color: white;
  border: solid 3px #4294d1;
  border-radius: 50px;
}
#navList {
  margin-top: 20vh;
  font-size: 16pt;
  font-weight: bold;
}
#navList li {
  margin: 7vh 0 0 -40px;
}
#menuFooter {
  margin-top: 7vh;
}
#menuSocialLinks, #footerSocialLinks {
  margin: 3vh 0;
  text-align: center;
}
#menuSocialLinks div, #footerSocialLinks div {
  display: inline-block;
  margin: 0 3vw;
  background-color: white;
  width: 30px;
  height: 30px;
  padding: 5px;
  border: 3px solid white;
  border-radius: 50px;
}
#menuFooter small {
  color: black;
}

/*app links*/
.App-link {

}

/*homePage*/
#homeH2 {
  font-family: 'Roboto', sans-serif;
  font-size: 12pt;
  font-weight: normal;
  color: black;
}
#homePageMap {
  background-image: url('/images/homepage-map.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-bottom: 5vh;
}
#centerHomeMapDiv {
  max-width: 500px;
  margin: 0 auto;
}
#centerHomeMapDiv div, #centerHomeMapDiv h3{
  display: inline-block;
}
#centerHomeMapDiv div {
  width: 5vw;
  height:7vh;
  margin: 20px 10px 0 0;
}
#centerHomeMapDiv h3 {
  font-size: 16pt;
}
#centerHomeMapDiv button {
  display: block;
  margin: 0 auto;
  padding: 8px;
  width: 130px;
  background-color: #41bb93;
  border: solid 3px #2d7f61;
  border-radius: 3px;
  font-size: 14pt;
  font-weight: bold;
  color: white;
}
#mapFindUs {
  text-align: left;
  font-size: 20pt;
  max-width: 250px;
}
#homePromotion div {
  background-image: url('/images/birthday-donut-home.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-bottom: 5vh;
}
#homePromotion h3 {
  color: black;
  max-width: 220px;
  text-align: center;
  margin: 0 auto;
  padding-top: 12vh;
}

/*location page*/
#locationHead {
  margin-bottom: 30px;
}
#locationHead p, #locationHead h2 {
  text-align: left;
  max-width: 500px;
  margin: 5px auto;
  padding-left: 15px;
}
#locationHead p span {
  font-weight: bold;
  color: #4294d1;
}
#mapAPIsection {
  min-height: 500px;
  margin-bottom: 30px;
  overflow: hidden;
}
.leaflet-container {
  height: 500px;
  width: 100%;
  margin: 0 auto;
}
#locationPromotion h3 {
  max-width: 300px;
  text-align: left;
  padding: 15vh 0 0 10vw;
  color: white;
  font-weight: normal;
  font-size: 20pt;

}
#locationPromotion div {
  background-image: url('/images/social-media-promo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-bottom: 5vh;
}

/*about us page*/
#aboutUs p {
  line-height: 2;
  max-width: 500px;
  margin: 20px auto;
  text-align: left;
}
#founders {
  max-width: 500px;
  margin: 0 auto;
}
#founders h2 {
  color: #f27581;
}
#founders h3 {
  font-family: 'Roboto', sans-serif;
}
.founderImg {
  width: 150px;
  height: 150px;
  background-color: grey;
  display: inline-block;
}
#founder1 {
  text-align: left;
  float: left;
  clear: both;
  margin-bottom: 50px;
}
#founder1desc {
  float: left;
}
#founder1img {
  float: left;
  background-image: url('/images/founder-1.jpg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  margin-right: 10px;
}
#founder2 {
  text-align: right;
  float: right;
  clear: both;
  margin-bottom: 100px;
}
#founder2desc {
 float: right;
}
#founder2img {
  float: right;
  background-image: url('/images/founder-2.jpg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100px;
  margin-left: 7px;
}
#aboutPromo {
  clear: both;
  background-image: url('/images/contact-promo.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 30vh;
  margin-bottom: 5vh;
}
#aboutPromo h3 {
  padding: 5vh 0 0 0;
  color: white;
  font-weight: normal;
  font-size: 20pt;
  margin: 0 auto;
}
#aboutPromo button {
  display: block;
  margin: 8vh auto;
  padding: 8px;
  width: 130px;
  background-color: #4294d1;
  border: solid 3px #4294d1;
  border-radius: 3px;
  font-size: 14pt;
  color: white;
}

/*contact page*/
#topContact {
  max-width: 480px;
  margin: 0 auto;
}
#phoneContact {
  background-image: url('/images/phone-contact.jpg');
  background-repeat: no-repeat;
  background-position: center;
}
#emailContact {
  background-image: url('/images/email-contact.jpg');
  background-repeat: no-repeat;
  background-position: center;
}
.contactInfo {
  background-color: #4294d1;
  color: white;
  margin-top: 87px;
  padding: 3px 0;
}
.contactInfo p {
  margin: 3px;
}
#topContact section {
  display: inline-block;
  text-align: center;
  margin: 20px 50px;
  width: 140px;
  height: 140px;
}
#contactForm {
  text-align: left;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 70px auto;
}
#inputForm input, #inputForm textarea {
  display: block;
  padding: 10px;
  border: 1px solid #f27581;
  margin: 10px auto;
  width: 90%;
  color: #f27581;
}
#inputForm textarea {
  height: 30vh;
}
#inputForm input[type=button] {
  background-color: #4294d1;
  border-radius: 3px;
  border: 1px solid #4294d1;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 14pt;
  padding: 15px;
  width: 250px;
}

/* menu page */
/**menu list**/
#menuListHead, #menuView {
  max-width: 500px;
  margin: 0 auto;
}
#menuListHead h2 {
  font-weight: normal;
  font-size: 14pt;
}
.itemTab {
  text-align: left;
  margin-bottom: 4vh;
}
.itemTab div {
  border: 3px solid #4294d1;
  height: 100px;
  overflow: hidden;
}
#menuView button {
  background-color: white;
  border-radius: 3px;
  border: 3px solid #4294d1;
  border-bottom: 0;
  color: #4294d1;
  font-size: 18pt;
  padding: 12px;
  width: 250px;
  margin-bottom: -1px;
}
/** all food views **/
.foodContainer {
  max-width: 500px;
  margin: 0 auto;
}
.foodBox {
  display: inline-block;
  width: 230px;
  height: 250px;
  margin: 0 5px 10px 5px;
  background-repeat: no-repeat;
  background-position: center top;
}
.foodInfo {
  background-color: #4294d1;
  width: 100%;
  height: 60px;
  margin-top: 190px;
}
.foodInfo h3 {
  color: white;
  text-align: left;
  padding: 5px 0 0 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.foodViewHead button {
  position: absolute;
  top: 120px;
  left: 10vw;
  height: 40px;
  width: 100px;
  text-align: left;
  font-size: 14pt;
  color: #f27581;
  background-color: white;
  border: 0;
}
.foodViewHead button span {
  font-size: 30pt;
  vertical-align: sub;
}
/*menu image fills */
/**donut page **/
#glazedDonut {
  background-image: url('/images/menu-donut-glazed.jpg');
}
#streuselDonut {
  background-image: url('/images/menu-donut-streusel.jpg');
}
#cocoDonut {
  background-image: url('/images/menu-coco-donut.jpg');
}
#berryDonut {
  background-image: url('/images/menu-berry-donut.jpg');
}
#oreoDonut {
  background-image: url('/images/menu-oreo-donut.jpg');
}
/**coffee page **/
#regCoffee {
  background-image: url('/images/menu-reg-coffee.jpg');
}
#latteCoffee {
  background-image: url('/images/menu-latte-coffee.jpg');
}
#mochaCoffee {
  background-image: url('/images/menu-mocha-coffee.jpg');
}
/**coffee page **/
#blackTea {
  background-image: url('/images/menu-black-tea.jpg');
}
#greenTea {
  background-image: url('/images/menu-green-tea.jpg');
}
#herbalTea {
  background-image: url('/images/menu-herbal-tea.jpg');
}
